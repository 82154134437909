<template>
    <div>
        <el-row>
            <el-col :span="18">
                <el-radio-group size="medium" v-model="status" @change="handleType">
                    <el-radio-button :label="0">全部</el-radio-button>
                    <el-radio-button :label="3">拨打</el-radio-button>
                    <el-radio-button :label="4">沟通</el-radio-button>
                    <el-radio-button :label="14">标签</el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="4">
                <el-button type="primary" size="medium" v-if="edit" v-permission="'waiter:student:exchange'" @click="dataFromSync = true">换绑</el-button>
            </el-col>
        </el-row>
        <el-row style="height: 82px;border: 1px #979797 outset;" v-for="item in historyList">
            <el-col :span="4" style="margin-top: 30px;font-size: 25px;text-align: center;">
                <i v-for="se in opporLogTypeList" :key="se.status" v-if="se.status === item.status"
                   :class="se.icon"></i>
            </el-col>
            <el-col :span="20" style="font-size: 13px;line-height: 10px;">
                <p>时间:{{ item.createTime }}</p>
                <p v-for="se in opporLogTypeList" :key="se.status" v-if="se.status === item.status">
                    操作:{{ se.name }}
                </p>
                <p>操作人:{{ item.createBy }}</p>
            </el-col>
        </el-row>

        <el-dialog title="换绑操作" :visible.sync="dataFromSync" width="600px" append-to-body>
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" label-width="150px" size="medium">
                <el-form-item label="输入手机号" prop="mobile">
                    <el-row>
                        <el-col :span="16">
                            <el-input v-model="mobile"></el-input>
                        </el-col>
                        <el-col :span="8" style="text-align: center">
                            <el-button type="primary" @click="findOpporMobile">查询</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="选择要绑定的记录" prop="opporIds">
                    <el-checkbox-group v-model="opporIdList">
                        <el-row v-for="item in opporList">
                            <el-checkbox :label="item.id">
                                <span v-for="pond in pondList" :key="pond.id"
                                      v-if="pond.id === item.pondId">成单类型: {{ pond.name }}</span>
                                <span style="margin-left: 20px;">{{ item.telephone }}</span>
                            </el-checkbox>
                        </el-row>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as studentApi from "@/api/waiter/waiterStudent";
import * as api from "@/utils/api";
import * as pondApi from "@/api/clue/cluePond";
import {saveData} from "@/api/waiter/waiterStudent";

export default {
    name: "WaiterStudentSaleRecord",
    data() {
        return {
            status: 0,
            more: false,
            historyList: [],
            opporLogTypeList: [],
            dataFromSync: false,
            dataFrom: {
                opporIds: ""
            },
            dataFromRules: {},
            mobile: "",
            pondList: [],
            opporList: [],
            opporIdList: []
        }
    },
    props: {
        studentId: {
            type: Number,
            require: true
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.status = 0
        this.findOpporLogTypeList()
        this.handleType()
        this.findPond()
    },
    methods: {
        findPond() {
            pondApi.listCluePond().then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
            })
        },
        findOpporMobile() {
            api.findOpporTelephone(this.mobile).then(res => {
                if (res.success) {
                    this.opporList = res.data
                }
            })
        },
        cancel() {
            this.dataFromSync = false
        },
        submitForm() {
            this.dataFrom.opporIds = this.opporIdList.join(",")
            this.dataFrom.studentId = this.studentId
            studentApi.saveData(this.dataFrom).then(res => {
                if (res.success) {
                    this.handleType()
                    this.$message.success(res.msg)
                    this.dataFromSync = false
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        findOpporLogTypeList() {
            api.findTypes().then(res => {
                this.opporLogTypeList = res
            })
        },
        handleType(e) {
            this.historyList = []
            this.findPage()
        },
        findPage() {
            let params = {id: this.studentId}
            if (this.status) {
                params.status = this.status
            }
            studentApi.history(params).then(res => {
                this.historyList = res.data;
                // let data = res.data;
                // this.historyList = data.map(item => {
                //     // 变更数据
                //     item.contextMap = item.content ? JSON.parse(item.content) : {}
                //     return item
                // })
            })
        },
    }
}
</script>

<style scoped>

</style>